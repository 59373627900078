import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import LayoutDark from '../components/layoutDark'
import Seo from '../components/seo'
import { Colors, Fonts, TypeScale, media } from '../components/styles/variables'
import { ButtonDark, FooterDark } from '../components/styles/elements'
import { Wrapper } from '../components/styles/grid'

const HomeWrapper = styled(Wrapper)`
  grid-template-rows: 15vh auto 15vh auto 5vh;

  ${media.lessThan('medium')`
    grid-template-rows: auto;
    grid-row-gap: 40px;
    grid-auto-rows: auto;
  `}
`

const SiteIntro = styled.h1`
  grid-column: 2 / span 13;
  grid-row: 2;
  margin: 0;
  line-height: 1.35;
  font-family: ${Fonts.serif};
  font-size: ${TypeScale.four};
  font-weight: normal;

  > strong {
    color: ${Colors.orange};
    font-weight: normal;
  }

  ${media.lessThan('medium')`
    font-size: ${TypeScale.one};
    grid-row: auto;
  `
  }
`

const MoreLink = styled(Link)`
  font-size: .75em;
  text-decoration: none;
`

const ServiceIntro = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column-start: 2;
  grid-column-end: span 13;

  ${media.greaterThan('medium')`
    grid-row: 4;
    grid-column-end: span 4;

    &:nth-of-type(1) {
      grid-column-start: 2;
    }

    &:nth-of-type(2) {
      grid-column-start: 7;
    }

    &:nth-of-type(3) {
      grid-column-start: 12;
    }
  `}

  > h2 {
    margin-bottom: 1em;
    font-family: ${Fonts.serif};
    font-size: ${TypeScale.one};
    color: ${Colors.orange};
  }

  > p {
    flex-grow: 1;
  }

  > button {
    width: auto;
  }
`

const IndexPage = () => (
  <LayoutDark>
    <Seo
      title='Onishi Ltd | Adam Onishi Frontend Engineer and Photographer'
    />

    <HomeWrapper>
      <SiteIntro>
        Hi, I’m Adam, a <strong>Senior Frontend Engineer</strong>. I specialise in <strong>design systems</strong>, <strong>performance</strong> and <strong>accessibility</strong> and I’m passionate about helping businesses perfect their digital products with a user centric approach. <br /> <MoreLink to='/about'>Find out more &raquo;</MoreLink>
      </SiteIntro>

      <ServiceIntro>
        <h2>Design systems</h2>
        <p>A design system gives you a consistent, custom set of design components and coding conventions. Flexible and scaleable, it makes life easier for both designers and developers.</p>
        <ButtonDark to='/contact'>Get in touch &rsaquo;</ButtonDark>
      </ServiceIntro>

      <ServiceIntro>
        <h2>Development</h2>
        <p>From front-end to full-stack, working alone or alongside your existing team. No matter how big or small your organisation and whatever you’ve got in mind, let’s talk.</p>
        <ButtonDark to='/contact'>Get in touch &rsaquo;</ButtonDark>
      </ServiceIntro>

      <ServiceIntro>
        <h2>Speaking</h2>
        <p>Want me to speak at your event? <br />I talk regularly at industry events and conferences in the UK and internationally, covering topics such as performance and accessibility.</p>
        <ButtonDark to='/contact'>Get in touch &rsaquo;</ButtonDark>
      </ServiceIntro>
    </HomeWrapper>
  </LayoutDark>
)

export default IndexPage
